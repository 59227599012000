import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`PayPerks was named “The One to Watch” at the Paybefore Awards (held at the All Payments Expo in Las Vegas on March 4th)!`}</p>
    <p>{`The best in category award, which is given to the up-and-coming prepaid/emerging payments business offering the program, product or service with staying power and the potential to move prepaid/emerging payments forward—over the long term, foreshadows the staying power of PLS on emerging payments products. The Paybefore write up explains that the judges chose PayPerks as it is “poised to drive meaningful, differentiating and scalable impact for both the profitability of the financial services provider and for the end-users of the platform.”`}</p>
    <p>{`A `}<em parentName="p">{`winning`}</em>{` proposition, to be sure!`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1403px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "71.74999999999999%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAOABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQBAgX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHVXekC4f/EABoQAAIDAQEAAAAAAAAAAAAAAAECAAMSERP/2gAIAQEAAQUCt9NK18TuGXRAIM//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAbEAABBAMAAAAAAAAAAAAAAAABABAREiEiMf/aAAgBAQAGPwIUMBbQhbJbrf/EABwQAQEAAgIDAAAAAAAAAAAAAAERACEQYTFR8P/aAAgBAQABPyF6lBtLvKSgXcD7rPMPYyZMak7y4WnfH//aAAwDAQACAAMAAAAQBN//xAAWEQEBAQAAAAAAAAAAAAAAAAABEQD/2gAIAQMBAT8Qplrd/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAHBABAAIDAAMAAAAAAAAAAAAAAREhADFREIGR/9oACAEBAAE/EC2slYkQa5PMU85MwhsD38XjaDbBZfMGTgqEY4gKIXj/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/f7e7aacbbeb2146b730e98b266de6259/7f61c/17_2014AwardsBIC_logoC-01.webp 400w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/f7e7aacbbeb2146b730e98b266de6259/d00b9/17_2014AwardsBIC_logoC-01.webp 800w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/f7e7aacbbeb2146b730e98b266de6259/5aba4/17_2014AwardsBIC_logoC-01.webp 1403w"],
            "sizes": "(max-width: 1403px) 100vw, 1403px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/f7e7aacbbeb2146b730e98b266de6259/066f9/17_2014AwardsBIC_logoC-01.jpg 400w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/f7e7aacbbeb2146b730e98b266de6259/4b190/17_2014AwardsBIC_logoC-01.jpg 800w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/f7e7aacbbeb2146b730e98b266de6259/ee644/17_2014AwardsBIC_logoC-01.jpg 1403w"],
            "sizes": "(max-width: 1403px) 100vw, 1403px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "https://cdn.payperks.com/payperks-corporate/1.16.1/static/f7e7aacbbeb2146b730e98b266de6259/ee644/17_2014AwardsBIC_logoC-01.jpg",
            "alt": "Best in Category",
            "title": "Best in Category",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      